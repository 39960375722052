<template>
  <div>
    <b-modal id="modal-inventory-po" size="xl" title="Pilih Obat" hide-footer>
      <Table
        purpose="modal"
        secondPurpose="inventory-purchase-order"
        :selectedItem="selectedItem"
        :checkbox="checkbox"
        @checkItem="checkItem"
      />
    </b-modal>
  </div>
</template>

<script>
import Table from "@/component/inventory/Table.vue";

export default {
  props: {
    selectedItem: Array,
    checkbox: Boolean,
    isHospitalTransaction: Boolean,
    isSortcut: Boolean,
    isAbjad: Boolean,
    secondPurpose: {
      type: String,
      default: "listing",
    },
  },

  components: {
    Table,
  },

  methods: {
    checkItem(value) {
      this.$emit("checkItem", value);
    },
  },
  mounted() {
    console.log(this.isSortcut, "sortcut");
  },
};
</script>

<style>
</style>